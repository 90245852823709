<template>
    <Modal v-model="showStationResult" width="1000" title="特色站点检索" footer-hide :mask-closable="false">
        <Row>
            <i-col span="3"><label class="title">查询关键字</label></i-col>
            <i-col span="8">
                <Input size="small" v-model="query.keywords" :search="query.keywords.length>0" enter-button="搜索"
                  @on-search="handleSearch" placeholder="输入关键字：商家、机构、站点名称等" />
            </i-col>
            <i-col span="8" class="p-l-20">
                <RadioGroup v-model="query.keyType" @on-change="handleSearch">
                    <Radio :label="0">全部</Radio>
                    <Radio :label="1">站点</Radio>
                    <Radio :label="2">商家</Radio>
                </RadioGroup>
            </i-col>
            <i-col span="5" class="text-right">
              <i-button type="success" @click="handleSubmit">确认设置</i-button>
            </i-col>
        </Row>
        <Row>
            <i-col span="3" class="p-t-5"><label class="title">搜索半径</label></i-col>
            <i-col span="8">
                <Slider  v-model="query.distance"
                    :step="50"
                    :max="1000"
                    show-tip="never"
                    @on-change="handleSearch"
                ></Slider>
            </i-col>
            <i-col span="3" class="p-t-5">
                <span class="text-orange m-l-10">{{ query.distance }} 米</span>
            </i-col>
        </Row>
        <Row v-if="currentCheckCity.length>0">
            <i-col span="3"><label class="title">已选站点</label></i-col>
            <i-col span="21">
                <p v-for="(city,index) in currentCheckCity" :key="index">
                  {{city.cityName}}：
                  <Tag color="gray" v-for="checkStation in currentCheckStaions.filter(x=>x.cityCode===city.cityCode)" :key="checkStation.stationId"
                    closable  @on-close="handleRemoveStation(checkStation.stationId)">
                    {{checkStation.stationName}}
                  </Tag>
                </p>
            </i-col>
        </Row>

        <div v-show="!showSimilar">
            <Row class="p-b-5 p-t-10">
                <i-col span="3"><label class="title">查询结果</label></i-col>
                <i-col span="21">
                    <a v-for="(city,index) in searchResultGroupCity" :key="index"
                      @click="handleGroupCity(city.cityCode)" class="m-r-10" :class="chooseGroupCity===city.cityCode?'choose-active':''">
                      {{city.cityName}}({{city.quantity}})
                    </a>
                </i-col>
            </Row>
            <Table id="station_table" size="small"
                :loading="loading"
                stripe
                :columns="searchColumns"
                :data="searchTableData"
                @on-selection-change="selectionChange"
                @on-sort-change="handleTableSort"
            ></Table>

            <div class="paging_style">
              <Page  size="small"
              :current="query.pageNumber"
              :total="totalRow"
              :page-size="query.pageSize"
              @on-change="handlePageChange"
              show-total
              />
            </div>
        </div>

        <div v-show="showSimilar">
            <Divider >{{chooseStationBean.stationName}} 相似站点</Divider>
            <p><a @click="handleReturnList"><Icon type="ios-undo" class="m-r-5" size="16" />返回查看结果</a></p>
            <Row class="p-b-10">
                <i-col span="24">
                    <h3>同城:</h3>
                    <CheckboxGroup v-model="checkSimilar" @on-change="handleCheckStation">
                        <Checkbox v-for="station in similarStations.filter(x=>x.cityCode===chooseStationBean.cityCode)"
                          :key="station.id" :label="station.id">{{station.assetName}} - {{station.name}}</Checkbox>
                    </CheckboxGroup>

                    <span class="remark" v-if="similarStations.filter(x=>x.cityCode===chooseStationBean.cityCode).length===0">暂无数据</span>
                </i-col>
            </Row>
            <Row>
                <i-col span="24">
                    <h3>其他城市:</h3>
                    <CheckboxGroup v-model="checkSimilar" @on-change="handleCheckStation">
                        <Checkbox v-for="station in similarStations.filter(x=>x.cityCode!==chooseStationBean.cityCode)"
                          :key="station.id" :label="station.id">{{station.assetName}} - {{station.name}}</Checkbox>
                    </CheckboxGroup>

                    <span class="remark" v-if="similarStations.filter(x=>x.cityCode!==chooseStationBean.cityCode).length===0">暂无数据</span>
                </i-col>
            </Row>

            <Spin fix size="large" v-if="spinShow">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>加载中...</div>
            </Spin>
        </div>
    </Modal>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getSimilarStations } from '@/api/product/condition'
import { toMoneyNoPrefix, toNumber } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        distance: 500,
        keywords: '',
        productId: null,
        cityCodes: [],
        dynamicLevel: 0,
        keyType: 0, // 0:全部;1:站点,2:商家;
        indexes: [
          { index: '11', start: 0, end: 100 },
          { index: '12', start: 0, end: 100 },
          { index: '13', start: 0, end: 100 },
          { index: '21', start: 0, end: 100 },
          { index: '22', start: 0, end: 100 },
          { index: '23', start: 0, end: 100 },
          { index: '31', start: 0, end: 100 }
        ],
        orderByStr: 'number',
        pageSize: 10,
        pageNumber: 1
      },

      showStationResult: false,
      loading: false,
      searchColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '站名', width: 100, key: 'stationName' },
        { title: '线路', width: 130, key: 'assetName' },
        {
          title: '品牌(个)',
          key: 'interBrand',
          width: 110,
          align: 'center',
          sortable: 'custom',
          render: (h, params) => {
            return h('span', params.row.domesticBrand + params.row.interBrand)
          }
        },
        {
          title: '房价(元)',
          key: 'housePrice',
          sortable: 'custom',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('span', params.row.housePrice === 0 ? '-' : toMoneyNoPrefix(params.row.housePrice))
          }
        },
        {
          title: '客单价(元)',
          key: 'consumePrice',
          sortable: 'custom',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return h('span', params.row.consumePrice === 0 ? '-' : toMoneyNoPrefix(params.row.consumePrice))
          }
        },
        {
          title: '进出站月客流',
          key: 'flowNumber',
          sortable: 'custom',
          align: 'center',
          width: 130,
          render: (h, params) => {
            return h('span', params.row.flowNumber === 0 ? '-' : toNumber(params.row.flowNumber))
          }
        },
        {
          title: '相关度',
          key: 'rank',
          width: 100,
          sortable: 'custom',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.rank + '%')
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showSimilar = true
                    this.searchSimilarStations(params.row)
                  }
                }
              }, '相似站点'
            )
          }
        }
      ],
      searchTableData: [],
      totalRow: 0,
      searchResultGroupCity: [], // 城市分组
      chooseGroupCity: '', // 选中的城市分组

      showSimilar: false,
      spinShow: false,
      chooseStationBean: {},
      similarStations: [], // 相似站点集合
      checkSimilar: [],

      currentCheckStaions: [], // 当前选中的全部站点
      currentCheckCity: [] // 当前选中的站点城市分组
    }
  },
  methods: {
    initData (keywords, productId) {
      this.showStationResult = true
      this.query.keywords = keywords
      this.query.productId = productId
      this.query.pageNumber = 1
      this.query.cityCodes = []
      this.query.orderByStr = 'number'
      this.query.orderByType = null
      this.chooseGroupCity = ''

      // 获取城市数据汇总
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/condition/getcityquantity',
        this.query,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        this.searchResultGroupCity = res.data
        this.loadSearchResult()
      })
    },
    loadSearchResult () {
      // this.loading = true
      this.showSimilar = false

      // 获取查询结果
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/condition/getsearchresultpage',
        this.query,
        {
          headers: {
            'Content-Type': 'application/json',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true
        }
      ).then(res => {
        // this.loading = false
        this.searchTableData = res.data.list
        this.totalRow = res.data.totalRow
      })
    },
    handleSearch () {
      if (this.query.keywords.length > 0) {
        this.initData(this.query.keywords, this.query.productId)
      }
    },
    handleGroupCity (cityCode) {
      if (cityCode === '' || this.chooseGroupCity === cityCode) {
        this.chooseGroupCity = ''
        this.query.cityCodes = []
      } else {
        this.chooseGroupCity = cityCode
        this.query.cityCodes = [cityCode]
      }

      this.query.pageNumber = 1
      this.loadSearchResult()
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.loadSearchResult()
    },
    handleTableSort (params) {
      this.query.pageNumber = 1
      if (params.order === 'normal') { // 取消排序
        this.query.orderByStr = 'number'
        this.query.orderByType = null
      } else {
        this.query.orderByStr = params.key
        this.query.orderByType = params.order === 'asc' ? 1 : 0
      }
      this.loadSearchResult()
    },
    searchSimilarStations (stationBean) {
      this.chooseStationBean = stationBean
      this.spinShow = true
      const postData = {
        distance: this.query.distance,
        productId: this.query.productId,
        quantity: 20,
        stationId: stationBean.stationId
      }

      getSimilarStations(postData).then(res => {
        this.similarStations = res
        // 设置默认已被列表选中的站点
        this.checkSimilar = []
        res.forEach(element => {
          if (this.currentCheckStaions.find(x => x.stationId === element.id)) {
            this.checkSimilar.push(element.id)
          }
        })

        this.spinShow = false
      })
    },
    handleCheckStation (item) {
      // 循环当前相似站点内的所有站点
      this.similarStations.forEach(column => {
        if (!item.includes(column.id)) {
          this.handleRemoveStation(column.id)
        }
      })

      // 如果当前选中站点集合中没有包含选中的站点, 那么添加改站点
      let checkStationBean = null
      item.forEach(element => {
        if (!this.currentCheckStaions.find(x => x.stationId === element)) {
          checkStationBean = this.similarStations.find(x => x.id === element)

          this.currentCheckStaions.push({
            cityCode: checkStationBean.cityCode,
            cityName: checkStationBean.cityName,
            stationId: checkStationBean.id,
            stationName: checkStationBean.name
          })
        }
      })

      this.groupCheckStationCity()
    },
    selectionChange (selection) {
      // 循环当前表格内的所有列
      this.searchTableData.forEach(column => {
        if (!selection.find(x => x.stationId === column.stationId)) {
          this.handleRemoveStation(column.stationId)
        }
      })

      // 如果当前选中站点集合中没有包含选中的站点, 那么添加改站点
      selection.forEach(element => {
        if (!this.currentCheckStaions.find(x => x.stationId === element.stationId)) {
          this.currentCheckStaions.push({
            cityCode: element.cityCode,
            cityName: element.cityName,
            stationId: element.stationId,
            stationName: element.stationName
          })
        }
      })

      this.groupCheckStationCity()
    },
    groupCheckStationCity () {
      this.currentCheckCity = []

      this.currentCheckStaions.forEach(element => {
        if (!this.currentCheckCity.find(x => x.cityCode === element.cityCode)) {
          this.currentCheckCity.push({
            cityCode: element.cityCode,
            cityName: element.cityName
          })
        }
      })
    },
    handleRemoveStation (stationId) {
      const stationIndex = this.currentCheckStaions.findIndex(x => x.stationId === stationId)

      if (stationIndex > -1) {
        this.currentCheckStaions.splice(stationIndex, 1)
      }
    },
    handleReturnList () {
      this.showSimilar = false
      const tempData = Object.assign({}, this.searchTableData)
      this.searchTableData = []
      this.searchTableData = tempData
    },
    handleSubmit () {
      this.$emit('on-change-stations', this.currentCheckStaions)
      this.showStationResult = false
    }
  }
}
</script>
