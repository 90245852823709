import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取相似站点
 * @param {Object} data
 */
export function getSimilarStations (data) {
  return request({
    url: '/ooh-product/v1/condition/getsimilarstations',
    method: 'POST',
    data: qs.stringify(data)
  })
}
